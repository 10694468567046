/**
 * 组合包装药品相关
 */
import request from '@/utils/request'
import { praseStrEmpty } from '@/utils/common'
import { saveOrUpdate } from './drugProd'

// 查询组合包装药品列表
export function comboDrugList(query) {
  return request({
    url: '/dkm/pkgDrugProd/comboDrugList',
    method: 'post',
    params: query
  })
}

// 查询单个组合包装药品的明细
export function comboDrugInfo(pkgDrugProdId) {
  return request({
    url: '/dkm/pkgDrugProd/comboDrugInfo/' + praseStrEmpty(pkgDrugProdId),
    method: 'get'
  })
}


// 新增 或 修改 组合包装药品
export function saveOrUpdateComboDrug(data) {
  return request({
    url: '/dkm/pkgDrugProd/editComboDrug',
    method: 'post',
    data: data
  })
}

// 删除组合包装药品
export function delComboDrug(id) {
  return request({
    url: '/dkm/pkgDrugProd/delComboDrug/' + praseStrEmpty(id),
    method: 'get'
  })
}

// 更新组合包装药品状态--0正常 1停用
export function updateComboDrugStatus( pkgDrugProdId,status) {
  return request({
    url: '/dkm/pkgDrugProd/updateComboDrugStatus',
    method: 'post',
    params: {pkgDrugProdId:pkgDrugProdId,status:status}
  })
}


// 根据名称、拼音、五笔码等模糊查询前50条组合包装药品名称
export function selectComboDrugNameList(queryStr) {
  return request({
    url: '/dkm/pkgDrugProd/selectComboDrugNameList',
    method: 'post',
    params: {queryStr:queryStr}
  })
}


// 查询详细的包装材料列表
export function selectPdProdMatDetailList(query) {
  return request({
    url: '/dkm/pkgDrugProd/selectPdProdMatDetailList',
    method: 'post',
    params: query
  })
}


