<!--药品分类  -- 条件编辑器-->
<template>
  <div>
    <el-button icon="el-icon-circle-plus-outline"
               style="float: right;margin-bottom: 6px;"
               size="small" class="addClass" @click="editCondition()">新增规则
    </el-button>
    <el-table
      border
      stripe
      :header-cell-style="{'text-align':'center'}"

      :data="conditionTableData"
    >
      <el-table-column type="index" align="center" width="50"></el-table-column>
      <el-table-column align="left" label="规则" min-width="100">
        <template slot-scope="scope">
          <span v-for="(condition,index) in scope.row.conditionList" class="condition-div">
            <span style="color: #4B56F2">{{ condition.fieldName }}</span>
             等于
            <el-tag v-for="t in condition.val" size="small"
                    style="margin-right: 4px;" >{{ t.name }}
            </el-tag>
            <el-tag type="info" v-if="index<scope.row.conditionList.length-1" style="margin: 0px 10px;">and</el-tag>
          </span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="100">
        <template slot-scope="scope">
          <el-button @click="editCondition(scope.row)" type="text"
                     size="small"
          >编辑
          </el-button>
          <el-button @click="delConditionRow(scope.row)" type="text" style="color: #FF3333;"
                     size="small"
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>


    <el-dialog
      top="5px"
      v-dialogDrag
      title="条件编辑"
      :visible.sync="conditionEditorDialogVisible"
      width="70%" append-to-body
    >
      <el-form ref="conditionEditForm"  class="el-form"  status-icon :model="conditionForm" label-width="120px">

        <el-form-item label="条件ID:" v-show="conditionForm.id">
          <el-input v-model="conditionForm.id" :disabled="true" style="width: 300px;float: left"/>
        </el-form-item>

        <el-form-item
          v-for="(item, index) in conditionForm.conditionList"
          :label="index==0?'条件:':''"
          :key="'condition-name-'+index"
          :prop="'conditionList.' + index + '.field'"
        >

          <el-form-item style="float: left;margin-bottom: 0px;"
                        :rules="[ { validator: validateConditionField}]"
                        :prop="'conditionList.' + index + '.field'">
            <el-select v-model="item.field" :disabled="item.val.length>0"
                       style="width: 150px;float: left;margin-right: 4px;">
              <el-option v-for="item in conditionFieldDict"
                         :key="item.label+'-'+item.value"
                         :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>



          <el-select v-model="item.func" style="width: 120px;float: left">
            <el-option label="等于" value="equals"></el-option>
          </el-select>
          <!--          <el-input placeholder="条件名称" v-model="item.name" style="width: 450px;float: left"-->
          <!--                    maxlength="90" clearable show-word-limit-->
          <!--          />-->

          <el-form-item style="float: left;margin-bottom: 0px;"
                        :rules="[
          { required: true, message: '请选择条件值', trigger: 'blur'},
          ]"
                        :prop="'conditionList.' + index + '.val'">
            <div class="condition-val">
              <el-tag v-for="(t,i) in item.val" size="medium" @close="removeConditionVal(index,i)"
                      style="margin-right: 4px;" closable>{{ t.name }}
              </el-tag>
              <el-button size="mini" round icon="el-icon-circle-plus-outline"
                         @click="addConditionVal(index)"
                         type="primary" plain></el-button>
            </div>
          </el-form-item>

          <i class="el-icon-remove" @click.prevent="delCondition(index)"
             v-show="conditionForm.conditionList.length>1"></i>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" plain class="addData" @click="addCondition"
                     style="width:300px;">
            <i class="el-icon-circle-plus el-icon--left"
               style="color:#7C9DF7;width:16px;height:16px;display:inline-block;"></i>
            增加条件
          </el-button>
        </el-form-item>


      </el-form>
      <span slot="footer" class="dialog-footer" style="padding-right: 30px;">
        <el-button @click="conditionEditorDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmSaveCondition">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      top="5px"
      v-dialogDrag
      title="新增条件值"
      :visible.sync="addConditionValDialogVisible"
      width="80%"
      append-to-body
      :close-on-click-modal="false"
      id="addConditionValDialog"
    >
      <div v-if="nowEditConditionField != 'form'">
        <el-row style="padding-bottom: 10px;">
          <el-input v-model="searchConditionValForm.searchValue" placeholder="" clearable
                    style="width:300px;margin-right: 20px;"
                    @keydown.enter.native="searchConditionValList(1)"
          ></el-input>
          <el-button type="primary" @click="searchConditionValList(1)">查询</el-button>
        </el-row>
        <el-table
          ref="conditionValTable"
          border
          :data="conditionValTableData"
          style="width: 100%;overflow: auto"
          height="400"
          v-loading="loading"
          :header-cell-style="{'text-align':'center'}"
          :cell-style="{'text-align':'center'}"
        >
          <el-table-column type="index" width="50"></el-table-column>
          <el-table-column width="100" property="id" label="药物ID" key="drug1"
                           v-if="nowEditConditionField=='drug'"></el-table-column>
          <el-table-column min-width="150" property="drugName" label="药物名称" key="drug2"
                           v-if="nowEditConditionField=='drug'"></el-table-column>
          <el-table-column width="80" property="classifyDesc" label="中西药标志" key="drug3"
                           v-if="nowEditConditionField=='drug'"></el-table-column>
          <el-table-column min-width="180" property="ingredient" label="药物成分" key="drug4"
                           v-if="nowEditConditionField=='drug'">
          </el-table-column>


          <el-table-column width="100" property="id" label="药物组ID" key="drugGroup1"
                           v-if="nowEditConditionField=='drugGroup'"></el-table-column>
          <el-table-column min-width="150" property="name" label="药物组名称" key="drugGroup2"
                           v-if="nowEditConditionField=='drugGroup'"></el-table-column>
          <el-table-column min-width="180" property="includeDrugList" label="包含药物列表" key="drugGroup3"
                           v-if="nowEditConditionField=='drugGroup'">
            <template slot-scope="scope">
              <el-popover trigger="click" placement="top">
                <div>
                  <p style="text-align:center;"><b>{{ scope.row.name }}</b></p>
                  <p v-for="item1 in scope.row.dkbDrugGroupMappingList" :key="item1.id">
                    [<span>{{ item1.drugId }}</span>]-<span>{{ item1.drugName }}</span>；
                  </p>
                </div>
                <span slot="reference" class="name-wrapper setTwoLine">
                  <span v-for="item1 in scope.row.dkbDrugGroupMappingList" :key="item1.id">
                    <span :class="{delClass: item1.status === '1'}">
                      [<span>{{ item1.drugId }}</span>]-<span>{{ item1.drugName }}</span>；
                    </span>
                  </span>
                </span>
              </el-popover>
            </template>
          </el-table-column>


          <el-table-column width="100" property="id" label="制剂ID" key="preparation1"
                           v-if="nowEditConditionField=='preparation'"></el-table-column>
          <el-table-column min-width="150" property="preparationAlias" label="制剂名称" key="preparation2"
                           v-if="nowEditConditionField=='preparation'"></el-table-column>
          <el-table-column width="80" property="typeName" label="制剂剂型" key="preparation3"
                           v-if="nowEditConditionField=='preparation'"></el-table-column>
          <el-table-column width="80" property="routeName" label="用药途径" key="preparation4"
                           v-if="nowEditConditionField=='preparation'"></el-table-column>
          <el-table-column width="80" property="siteName" label="给药部位" key="preparation5"
                           v-if="nowEditConditionField=='preparation'"></el-table-column>
          <el-table-column width="150" property="drugTypeSpecName" label="中药类型及规格" key="preparation6"
                           v-if="nowEditConditionField=='preparation'"></el-table-column>
          <el-table-column width="150" property="drugProcMethodName" label="中药炮制方法" key="preparation7"
                           v-if="nowEditConditionField=='preparation'"></el-table-column>


          <el-table-column width="100" property="id" label="给药途径ID" key="route1"
                           v-if="nowEditConditionField=='route'"></el-table-column>
          <el-table-column min-width="100" property="conceptVal" label="给药途径名称" key="route2"
                           v-if="nowEditConditionField=='route'"></el-table-column>

          <el-table-column width="100" property="id" label="给药部位ID" key="site1"
                           v-if="nowEditConditionField=='site'"></el-table-column>
          <el-table-column min-width="100" property="conceptVal" label="给药部位名称" key="site2"
                           v-if="nowEditConditionField=='site'"></el-table-column>

          <el-table-column label="操作" align="center" width="100" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" v-show="!scope.row.selected"
                         size="small" @click="addConditionValFromTable(scope.row)"
              >选择
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          v-show="conditionValTableDataTotal>0"
          :total="conditionValTableDataTotal"
          :page.sync="searchConditionValForm.pageNum"
          :limit.sync="searchConditionValForm.pageSize"
          layout="total, sizes, prev, pager, next"
          @pagination="searchConditionValList()"
          :page-sizes="[20, 50, 100]"
        />
      </div>
      <div v-if="nowEditConditionField == 'form'" style="height: 300px;overflow: auto;">
        <el-input
          placeholder="输入关键字进行过滤"
          v-model="searchConditionValForm.treeFilterText">
        </el-input>
        <el-tree :data="conceptFormTreeData" show-checkbox
                 ref="conceptFormTree"
                 node-key="conceptId"
                 :check-strictly="true"
                 :default-checked-keys="formTreeDefaultCheckedKeys"
                 @check-change="handleFormTreeCheckChange"
                 :filter-node-method="filterFormTreeNode"
                 :props="formTreeProps"></el-tree>
      </div>

    </el-dialog>
  </div>

</template>

<script>
import {list as drugList} from '@/api/dkm/drug'
import {list as drugGroupList} from '@/api/dkm/drugGroup'
import {preparationList} from '@/api/dkm/preparationSpecification'
import conceptConst from '@/const/conceptConst'
import {getConcept} from '@/utils/concept'
import {isEmpty} from '@/utils/common'
import {getFormConceptTree} from '@/utils/concept'
import {getConditionList, editCondition, delCondition} from '@/api/dkm/drugCategoryTreeList'

export default {
  name: 'conditionEditor',
  props: {
    categoryDirId: Number
  },
  watch: {
    "searchConditionValForm.treeFilterText": {
      deep: true,
      handler(newVal, oldVal) {
        this.$refs.conceptFormTree.filter(newVal);
        //this.dkbDp=newVal
      }
    }
  },
  data() {
    return {
      conceptConst,
      loading: false,
      conditionTableData: [],
      conditionEditorDialogVisible: false,
      addConditionValDialogVisible: false,
      searchConditionValForm: {
        searchValue: '',
        treeFilterText: '',
        pageNum: 1,
        pageSize: 20
      },
      conditionFieldDict: [
        {label: "药物", value: "drug"},
        {label: "药物组", value: "drugGroup"},
        {label: "制剂", value: "preparation"},
        {label: "给药途径", value: "route"},
        {label: "给药部位", value: "site"},
        {label: "剂型", value: "form"},
      ],
      conditionForm: {
        id: undefined,
        conditionList: []
      },
      nowEditConditionField: {},
      nowEditConditionVal: [],
      conditionValTableData: [],
      conditionValTableDataTotal: 0,
      conceptDictRoute: [],
      conceptDictSite: [],
      //剂型字典
      conceptFormTreeData: [],
      formTreeProps: {
        children: 'children',
        label: 'conceptVal',
        id: "conceptId",
        disabled:function (data, node){
          return data.children?true:false;
        }
      },
      formTreeDefaultCheckedKeys: []
    }
  },
  created() {
    this.getConceptData()
    this.selectConditionTableData()
  },
  computed: {},
  methods: {
    //查询规则列表数据
    selectConditionTableData() {
      getConditionList(this.categoryDirId).then(res => {
        this.conditionTableData = res.data
        this.conditionTableData.forEach(row => {
          row.conditionList.forEach(condition => {
            let fieldName = ''
            this.conditionFieldDict.some(fieldItem => {
              if (fieldItem.value == condition.field) {
                fieldName = fieldItem.label
                return true
              }
            })
            condition.fieldName=fieldName
          })
        })
      })
    },
    delConditionRow(row) {
      let that = this
      this.$confirm('确认要删除该条规则吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        delCondition(row.id).then(response => {
          that.selectConditionTableData()
          that.$emit("saveCondition")
          that.msgSuccess('删除成功')
        })
      })

    },
    editCondition(row) {
      let form = this.conditionForm
      form.categoryDirId = this.categoryDirId
      if (row == undefined) {
        //新增
        form.id = undefined
        form.conditionList = [{field: "drug", func: "equals", val: []}]
      } else {
        //修改
        form.id = row.id
        form.conditionList = row.conditionList
      }
      this.conditionEditorDialogVisible = true
    },
    addCondition() {
      this.clearFormValidate("conditionEditForm")
      this.conditionForm.conditionList.push({field: "drug", func: "equals", val: []})
    },
    delCondition(index) {
      this.conditionForm.conditionList.splice(index, 1)
    },
    //删除条件值
    removeConditionVal(conditionListIndex, valIndex) {
      let valList = this.conditionForm.conditionList[conditionListIndex].val
      valList.splice(valIndex, 1)
    },
    //点击增加条件值
    addConditionVal(index) {
      this.clearFormValidate("conditionEditForm")
      this.nowEditConditionField = ''
      this.searchConditionValForm.searchValue = ''
      this.searchConditionValForm.treeFilterText = ''
      this.searchConditionValForm.pageNum = 1
      this.searchConditionValForm.pageSize = 20
      this.$nextTick(() => {
        this.nowEditConditionField = this.conditionForm.conditionList[index].field
        this.nowEditConditionVal = this.conditionForm.conditionList[index].val
        this.searchConditionValList(1)
      })

      this.addConditionValDialogVisible = true


    },
    //从表格选择一行增加条件值
    addConditionValFromTable(row) {
      this.nowEditConditionVal.push({id: row.id, name: row.name})
      //处理已选择的项目，设置为不允许再次选择
      this.conditionValTableData.forEach(row => {
        row.selected = this.nowEditConditionVal.some(val => {
          return val.id == row.id
        })
      })
      this.$nextTick(() => {
        this.$refs.conditionValTable.doLayout()
      })

    },
    //确定保存编辑
    confirmSaveCondition() {
      this.$refs['conditionEditForm'].validate(valid => {
        if (valid) {
          this.conditionEditorDialogVisible = false
          editCondition(this.conditionForm).then(result => {
            this.msgSuccess()
            this.selectConditionTableData()
            this.$emit("saveCondition")
          })
        } else {
          this.msgError('请规范填写')
        }
      })

    },
    async searchConditionValList(type) {
      let field = this.nowEditConditionField
      this.loading = true
      if (field == "drug") {
        //查询药物
        await this.selectDrugList()
      } else if (field == "drugGroup") {
        //查询药物组列表
        await this.selectDrugGroupList()
      } else if (field == "preparation") {
        //查询制剂
        await this.selectPreparationList()
      } else if (field == "route" || field == "site") {
        this.loading = false
        this.searchConditionValForm.pageSize = 100
        if (field == "route") {
          this.conditionValTableData = this.conceptDictRoute
        } else if (field == "site") {
          this.conditionValTableData = this.conceptDictSite
        }

        let search = this.searchConditionValForm.searchValue.trim()
        if (!isEmpty(search)) {
          this.conditionValTableData = this.conditionValTableData.filter(
            item => item.conceptVal.indexOf(search) > -1)
        }
        this.conditionValTableDataTotal = this.conditionValTableData.length

      } else if (field == "form") {
        //设置剂型树结构的默认选中项目
        this.formTreeDefaultCheckedKeys = []
        this.nowEditConditionVal.forEach(item => {
          this.formTreeDefaultCheckedKeys.push(item.id)
        })

      }

      if (field != "form") {
        //处理已选择的项目，设置为不允许再次选择
        this.conditionValTableData.forEach(row => {
          row.selected = this.nowEditConditionVal.some(val => {
            return val.id == row.id
          })
        })

        this.$nextTick(() => {
          this.$refs.conditionValTable.doLayout()
        })
      }

    },
    /*查询药物列表*/
    async selectDrugList() {
      await drugList(this.searchConditionValForm).then(response => {
        this.loading = false
        this.conditionValTableDataTotal = response.total
        this.conditionValTableData = response.rows
        this.conditionValTableData.forEach((item) => {
          //药物名称
          if (item.dkbDrugAliasList) {
            item.dkbDrugAliasList.forEach((aliasItem) => {
              if (aliasItem.isPrimary && aliasItem.isPrimary == '1') {
                item.drugName = aliasItem.alias
                return
              }
            })
          }
          item.name = item.drugName

          //药物成分
          if (item.dkbDrugIngredientList) {
            item.ingredient = ''
            item.dkbDrugIngredientList.forEach((t) => {
              item.ingredient += t.substanceName + ';'
            })
          }
        })
      })
    },
    /*查询药物组列表*/
    async selectDrugGroupList() {
      await drugGroupList(this.searchConditionValForm).then(response => {
        this.loading = false
        this.conditionValTableDataTotal = response.total
        this.conditionValTableData = response.rows
      })
    },
    /*查询制剂列表*/
    async selectPreparationList() {
      this.searchConditionValForm.name = this.searchConditionValForm.searchValue
      await preparationList(this.searchConditionValForm).then(response => {
        this.loading = false
        this.conditionValTableDataTotal = response.data.total
        this.conditionValTableData = response.data.rows
        this.conditionValTableData.forEach((item) => {
          item.id = item.preparationId
          item.name = item.preparationAlias
        })
      })
    },
    // 获取概念域数据
    getConceptData() {
      getConcept(conceptConst.DKB_ADMINISTRATION_ROUTE, 0).then(result => {
        result.forEach((item) => {
          item.id = item.conceptId
          item.name = item.conceptVal
        })
        this.conceptDictRoute = result
      })

      getConcept(conceptConst.DKB_APPLICATION_SITE, 0).then(result => {
        result.forEach((item) => {
          item.id = item.conceptId
          item.name = item.conceptVal
        })
        this.conceptDictSite = result
      })

      /*获取剂型字典*/
      getFormConceptTree().then(result => {
        this.conceptFormTreeData = result
      })
    },

    //剂型树，节点选中和取消事件
    handleFormTreeCheckChange(data, checked, indeterminate) {
      if (checked) {
        //选中
        this.nowEditConditionVal.push({id: data.conceptId, name: data.conceptVal})
      } else {
        //取消选中
        this.nowEditConditionVal.some((item, index) => {
          if (item.id == data.conceptId) {
            this.nowEditConditionVal.splice(index, 1)
            return true
          }
        })
      }
    },
    //过滤制剂树的节点
    filterFormTreeNode(value, data) {
      if (isEmpty(value)) return true;
      return (data.conceptVal && data.conceptVal.indexOf(value.trim()) !== -1) || (data.spell && data.spell.indexOf(value.trim()) !== -1);
    },
    //条件的field 校验
    validateConditionField(rule, value, callback, index) {
      let conditionList=this.conditionForm.conditionList
      let find= conditionList.some(item => {
        return item.field=='drug' || item.field=='drugGroup' || item.field=='preparation'
      })


      let find2= conditionList.some(item => {
        return item.field=='preparation'
      })


      if(find2 && ['route','site','form'].indexOf(value)>-1){
        callback(new Error('制剂条件不能与[给药途径/给药部位/剂型]联合使用!'))
      }else if(find){
        callback()
      }else{
        callback(new Error('当前条件必须与[药物/药物组]联合使用!'))
      }
    }
  }
}
</script>.trim()

<style scoped>
.condition-val {
  margin-left: 5px;
  min-width: 400px;
  max-width: 500px;
  min-height: 36px;
  border: 1px solid #dfe6ec;
  padding: 0px 6px 0px 6px;
  float: left;
  border-radius: 6px;
}

/*删除按钮*/
.el-icon-remove {
  color: #F52C2C;
  font-size: 20px;
  padding: 0px 6px;
}

.el-form >>> .el-form-item__content{
  padding-bottom: 3px;
}

.condition-div .el-divider--horizontal{
  margin:8px 0px;
}
</style>
