var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", staticStyle: { height: "100%" } },
    [
      _c(
        "el-row",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.treeLoading,
              expression: "treeLoading",
            },
          ],
          staticStyle: { height: "100%" },
        },
        [
          _c(
            "el-col",
            {
              staticStyle: {
                height: "100%",
                "border-right": "1px solid #ccc",
                "padding-right": "6px",
              },
              attrs: { span: 8 },
            },
            [
              _c(
                "el-row",
                { staticStyle: { "text-align": "left", padding: "10px 0px" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "border-left": "5px solid #0073E9",
                        "padding-left": "10px",
                        height: "20px",
                      },
                      attrs: { span: 24 },
                    },
                    [_vm._v("药品类别目录树")]
                  ),
                  _c(
                    "el-row",
                    { staticStyle: { "padding-top": "10px" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 16 } },
                        [
                          _c(
                            "el-input",
                            {
                              staticStyle: { "min-width": "100px" },
                              attrs: {
                                size: "small",
                                maxlength: "50",
                                clearable: "",
                                placeholder: "输入关键字进行过滤",
                              },
                              model: {
                                value: _vm.treeFilterText,
                                callback: function ($$v) {
                                  _vm.treeFilterText = $$v
                                },
                                expression: "treeFilterText",
                              },
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  slot: "append",
                                  icon: "el-icon-search",
                                },
                                slot: "append",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "padding-left": "10px" },
                          attrs: { span: 8 },
                        },
                        [
                          _c("el-button", {
                            attrs: {
                              icon: "el-icon-refresh",
                              type: "primary",
                              size: "small",
                              title: "刷新",
                            },
                            on: { click: _vm.treeList },
                          }),
                          _c("el-button", {
                            attrs: {
                              icon: "el-icon-circle-plus-outline",
                              type: "info",
                              size: "small",
                              title: "新增",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.addNode()
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.showDirTree
                ? _c("el-tree", {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.treeLoading,
                        expression: "treeLoading",
                      },
                    ],
                    ref: "cateGoryTree",
                    staticStyle: {
                      height: "calc(100% - 90px)",
                      overflow: "auto",
                    },
                    attrs: {
                      data: _vm.treeData,
                      props: _vm.defaultProps,
                      "current-node-key": 1,
                      "highlight-current": true,
                      "node-key": "id",
                      "filter-node-method": _vm.filterNode,
                      "expand-on-click-node": false,
                    },
                    on: { "node-click": _vm.handleNodeClick },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var node = ref.node
                            var data = ref.data
                            return _c(
                              "div",
                              {
                                staticClass: "custom-tree-node",
                                on: {
                                  mouseenter: function ($event) {
                                    return _vm.treeNodeMouseEnter(data)
                                  },
                                  mouseleave: function ($event) {
                                    return _vm.treeNodeMouseLeave(data)
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "light",
                                      placement: "top",
                                      "open-delay": 600,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "font-size": "14px",
                                          "max-width": "400px",
                                        },
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-bottom": "2px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  data.code +
                                                    "-" +
                                                    data.name +
                                                    "-" +
                                                    data.id
                                                )
                                            ),
                                          ]
                                        ),
                                        _c("br"),
                                        _vm._v(_vm._s(data.comment)),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      { staticClass: "tree-node-name" },
                                      [
                                        data.icon
                                          ? _c("svg-icon", {
                                              attrs: {
                                                "icon-class": data.icon,
                                              },
                                            })
                                          : _vm._e(),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: data.categoryType == 15,
                                                expression:
                                                  "data.categoryType==15",
                                              },
                                            ],
                                          },
                                          [_vm._v(_vm._s(data.code + "-"))]
                                        ),
                                        _vm._v(
                                          " " +
                                            _vm._s(data.name + "-" + data.id) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "min-width": "130px",
                                      display: "inline-block",
                                    },
                                  },
                                  [
                                    data.show && node.expanded == false
                                      ? _c("el-link", {
                                          staticStyle: {
                                            position: "relative",
                                            "margin-left": "8px",
                                          },
                                          attrs: {
                                            size: "mini",
                                            type: "primary",
                                            underline: false,
                                            title: "展开全部",
                                            icon: "fa fa-chevron-circle-right fa-lg",
                                          },
                                          on: {
                                            click: [
                                              function ($event) {
                                                $event.stopPropagation()
                                              },
                                              function () {
                                                return _vm.expandNode(
                                                  node,
                                                  data
                                                )
                                              },
                                            ],
                                          },
                                        })
                                      : _vm._e(),
                                    data.show && node.expanded == true
                                      ? _c("el-link", {
                                          staticStyle: {
                                            position: "relative",
                                            "margin-left": "8px",
                                          },
                                          attrs: {
                                            size: "mini",
                                            type: "primary",
                                            underline: false,
                                            title: "折叠全部",
                                            icon: "fa fa-chevron-circle-down fa-lg",
                                          },
                                          on: {
                                            click: [
                                              function ($event) {
                                                $event.stopPropagation()
                                              },
                                              function () {
                                                return _vm.foldNode(node, data)
                                              },
                                            ],
                                          },
                                        })
                                      : _vm._e(),
                                    data.show && data.allowEdit == 1
                                      ? _c("el-link", {
                                          staticStyle: {
                                            "margin-left": "8px",
                                            position: "relative",
                                          },
                                          attrs: {
                                            size: "mini",
                                            type: "primary",
                                            underline: false,
                                            title: "编辑",
                                            icon: "fa fa-edit fa-lg",
                                          },
                                          on: {
                                            click: [
                                              function ($event) {
                                                $event.stopPropagation()
                                              },
                                              function () {
                                                return _vm.editNode(node, data)
                                              },
                                            ],
                                          },
                                        })
                                      : _vm._e(),
                                    data.show && data.allowEdit == 1
                                      ? _c("el-link", {
                                          staticStyle: {
                                            "margin-left": "8px",
                                            position: "relative",
                                          },
                                          attrs: {
                                            size: "mini",
                                            type: "primary",
                                            underline: false,
                                            title: "新增子级",
                                            icon: "fa fa-plus-square fa-lg",
                                          },
                                          on: {
                                            click: [
                                              function ($event) {
                                                $event.stopPropagation()
                                              },
                                              function () {
                                                return _vm.addNode(data)
                                              },
                                            ],
                                          },
                                        })
                                      : _vm._e(),
                                    data.show && data.allowEdit == 1
                                      ? _c("el-link", {
                                          staticStyle: {
                                            "margin-left": "8px",
                                            position: "relative",
                                          },
                                          attrs: {
                                            size: "mini",
                                            type: "primary",
                                            underline: false,
                                            title: "删除",
                                            icon: "fa fa-trash-o fa-lg",
                                          },
                                          on: {
                                            click: [
                                              function ($event) {
                                                $event.stopPropagation()
                                              },
                                              function () {
                                                return _vm.remove(node, data)
                                              },
                                            ],
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          },
                        },
                      ],
                      null,
                      false,
                      3693954814
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-col",
            {
              staticStyle: { height: "100%", "padding-left": "6px" },
              attrs: { span: 16 },
            },
            [
              _c(
                "el-row",
                {
                  staticStyle: { padding: "10px 0px" },
                  attrs: { id: "relevanceMsgRow" },
                },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: {
                            "border-left": "5px solid #0073E9",
                            "padding-left": "10px",
                          },
                          attrs: { span: 24 },
                        },
                        [_vm._v("关联列表")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { color: "#409eff" }, attrs: { span: 10 } },
                    [
                      _c("p", [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-weight": "bolder",
                              "margin-right": "20px",
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.nowClickNodeData.name))]
                        ),
                        _c("span", { staticStyle: { "font-weight": "300" } }, [
                          _vm._v(_vm._s(_vm.nowClickNodeData.comment)),
                        ]),
                      ]),
                      _vm._l(
                        _vm.nowClickNodeData.extPropertyList,
                        function (item) {
                          return _c("p", [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-weight": "300",
                                  "padding-right": "10px",
                                },
                              },
                              [_vm._v(_vm._s(item.propertyName + ":"))]
                            ),
                            _c(
                              "span",
                              { staticStyle: { "font-weight": "300" } },
                              [_vm._v(_vm._s(item.propertyVal))]
                            ),
                          ])
                        }
                      ),
                    ],
                    2
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "text-align": "right" },
                      attrs: { span: 10 },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "addClass",
                          attrs: {
                            icon: "el-icon-circle-plus-outline",
                            disabled: _vm.treeLoading ? true : false,
                            size: "mini",
                          },
                          on: { click: _vm.addTableList },
                        },
                        [_vm._v("新增 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "addClass",
                          attrs: {
                            icon: "el-icon-s-operation",
                            disabled: _vm.treeLoading ? true : false,
                            size: "mini",
                          },
                          on: {
                            click: function ($event) {
                              _vm.conditionEditorVisible = true
                            },
                          },
                        },
                        [_vm._v("条件设置 ")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "addClass",
                          attrs: {
                            icon: "el-icon-refresh",
                            disabled: _vm.treeLoading ? true : false,
                            size: "mini",
                          },
                          on: { click: _vm.refreshDirRefData },
                        },
                        [_vm._v("数据同步 ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              true
                ? _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      ref: "selectRelevanceDataTable",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.tableData,
                        border: "",
                        height: _vm.tableHeight,
                        "header-cell-style": { "text-align": "center" },
                        "cell-style": { "text-align": "center" },
                        "highlight-current-row": "",
                        "default-sort": {
                          prop: "drugFlagCode",
                          order: "descending",
                        },
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "index", fixed: "left", width: "50" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "drugFlagTypeStr",
                          label: "标识类型",
                          width: "130",
                          sortable: "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "drugFlagCode",
                          label: "标识ID",
                          width: "80",
                          sortable: "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "name",
                          label: "名称",
                          "min-width": "200",
                          sortable: "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "desc",
                          label: "描述",
                          "min-width": "200",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-popover",
                                    {
                                      attrs: {
                                        placement: "top",
                                        title: "描述",
                                        trigger: "click",
                                        width: "600",
                                      },
                                    },
                                    [
                                      _c("div", [
                                        _c("p", [
                                          _vm._v(
                                            " " + _vm._s(scope.row.desc) + " "
                                          ),
                                        ]),
                                      ]),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "setTwoLine",
                                          staticStyle: {
                                            color: "#2B60F8",
                                            "font-weight": "700",
                                          },
                                          attrs: {
                                            slot: "reference",
                                            title: "点击查看全部描述",
                                          },
                                          slot: "reference",
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(scope.row.desc) + " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2101756484
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "updateTime",
                          label: "更新时间",
                          width: "150",
                          sortable: "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "来源", width: "250" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  !scope.row.conditionId
                                    ? _c("p", [_vm._v(" 手动添加 ")])
                                    : _vm._e(),
                                  _vm._l(
                                    scope.row.conditionList,
                                    function (condition, index) {
                                      return scope.row.conditionId
                                        ? _c(
                                            "div",
                                            { staticClass: "condition-div" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "#4B56F2",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(condition.fieldName)
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" 等于 "),
                                              _vm._l(
                                                condition.val,
                                                function (t) {
                                                  return _c(
                                                    "el-tag",
                                                    {
                                                      staticStyle: {
                                                        "margin-right": "4px",
                                                      },
                                                      attrs: { size: "small" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(t.name) + " "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          )
                                        : _vm._e()
                                    }
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3365958602
                        ),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          align: "center",
                          "class-name": "small-padding fixed-width",
                          width: "100",
                          fixed: "right",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { color: "#FF3333" },
                                      attrs: { size: "mini", type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleDelete(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("删除 ")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1061005261
                        ),
                      }),
                      _c("template", { slot: "empty" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/no-booking.svg"),
                            alt: "",
                          },
                        }),
                        _c("p", [_vm._v("暂无数据")]),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total>0",
                  },
                ],
                attrs: {
                  total: _vm.total,
                  page: _vm.pageNum,
                  limit: _vm.pageSize,
                  layout: "total",
                  "page-sizes": [20, 50, 100, 500, 1000],
                },
                on: {
                  "update:page": function ($event) {
                    _vm.pageNum = $event
                  },
                  "update:limit": function ($event) {
                    _vm.pageSize = $event
                  },
                  pagination: function ($event) {
                    return _vm.getInfo()
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: _vm.form.editNode ? "编辑分类" : "新增分类",
            visible: _vm.addTreeVisible,
            width: "50%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.addTreeVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "editCategoryDirForm",
              attrs: { model: _vm.form, "label-width": "80px" },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "上级分类",
                    prop: "pid",
                    rules: [{ required: true, message: "上级分类不能为空" }],
                  },
                },
                [
                  _vm.addTreeVisible
                    ? _c("treeselect", {
                        attrs: {
                          options: _vm.selectTreeData,
                          normalizer: _vm.normalizer,
                          "show-count": true,
                          placeholder: "选择上级分类",
                        },
                        model: {
                          value: _vm.form.pid,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "pid", $$v)
                          },
                          expression: "form.pid",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "类别编码",
                    prop: "code",
                    rules: [
                      { required: true, message: "类别编码不能为空" },
                      { validator: _vm.validateCategoryCode, trigger: "blur" },
                    ],
                  },
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "50", "show-word-limit": "" },
                    model: {
                      value: _vm.form.code,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "code", $$v)
                      },
                      expression: "form.code",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "类别名称",
                    prop: "name",
                    rules: [
                      { required: true, message: "类别名称不能为空" },
                      { validator: _vm.validateCategoryName, trigger: "blur" },
                    ],
                  },
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "200", "show-word-limit": "" },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "comment" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 6,
                      maxlength: "1000",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.form.comment,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "comment", $$v)
                      },
                      expression: "form.comment",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-right": "20px" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.addTreeVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitEditTree("editCategoryDirForm")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            top: "5px",
            title: "新增关联列表",
            visible: _vm.addTableVisible,
            width: "80%",
            "before-close": _vm.addTableClose,
            "close-on-click-modal": false,
            id: "addRelevanceDialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addTableVisible = $event
            },
          },
        },
        [
          _c(
            "el-breadcrumb",
            {
              staticStyle: { padding: "6px" },
              attrs: { "separator-class": "el-icon-arrow-right" },
            },
            [
              _c(
                "el-breadcrumb-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeBreadcrumb >= 1,
                      expression: "activeBreadcrumb>=1",
                    },
                  ],
                },
                [
                  _c(
                    "div",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.breadcrumbItemClick(1)
                        },
                      },
                    },
                    [
                      _c("p", { staticClass: "breadcrumb-item-name" }, [
                        _vm._v("药物"),
                      ]),
                    ]
                  ),
                ]
              ),
              _c(
                "el-breadcrumb-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeBreadcrumb >= 2,
                      expression: "activeBreadcrumb>=2",
                    },
                  ],
                },
                [
                  _c(
                    "div",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.breadcrumbItemClick(2)
                        },
                      },
                    },
                    [
                      _c("p", { staticClass: "breadcrumb-item-name" }, [
                        _vm._v("制剂"),
                      ]),
                    ]
                  ),
                ]
              ),
              _c(
                "el-breadcrumb-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeBreadcrumb >= 3,
                      expression: "activeBreadcrumb>=3",
                    },
                  ],
                },
                [
                  _c(
                    "div",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.breadcrumbItemClick(3)
                        },
                      },
                    },
                    [
                      _c("p", { staticClass: "breadcrumb-item-name" }, [
                        _vm._v("制剂规格"),
                      ]),
                    ]
                  ),
                ]
              ),
              _c(
                "el-breadcrumb-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeBreadcrumb >= 4,
                      expression: "activeBreadcrumb>=4",
                    },
                  ],
                },
                [
                  _c("div", [
                    _c("p", { staticClass: "breadcrumb-item-name" }, [
                      _vm._v("药品"),
                    ]),
                  ]),
                ]
              ),
              _c(
                "el-breadcrumb-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.activeBreadcrumb >= 5,
                      expression: "activeBreadcrumb>=5",
                    },
                  ],
                },
                [
                  _c("div", [
                    _c("p", { staticClass: "breadcrumb-item-name" }, [
                      _vm._v("包装药品"),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { padding: "10px 0px" } },
            [
              _c(
                "el-col",
                { attrs: { span: 18 } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "relevanceQueryForm",
                      staticClass: "demo-form-inline",
                      attrs: { inline: true, model: _vm.addRelevanceForm },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "",
                            prop: "searchValue",
                            rules: [
                              {
                                required: false,
                                message: "请输入查询关键字",
                                trigger: "blur",
                              },
                            ],
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "350px" },
                            attrs: {
                              placeholder: "请输入关键字",
                              clearable: "",
                            },
                            nativeOn: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.searchDataList(1)
                              },
                            },
                            model: {
                              value: _vm.addRelevanceForm.searchValue,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.addRelevanceForm,
                                  "searchValue",
                                  $$v
                                )
                              },
                              expression: "addRelevanceForm.searchValue",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "120px" },
                              attrs: { placeholder: "请选择类型" },
                              on: { change: _vm.typeSelectChange },
                              model: {
                                value: _vm.addRelevanceForm.drugFlag,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.addRelevanceForm,
                                    "drugFlag",
                                    $$v
                                  )
                                },
                                expression: "addRelevanceForm.drugFlag",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "物质", value: "1" },
                              }),
                              _c("el-option", {
                                attrs: { label: "药物", value: "3" },
                              }),
                              _c("el-option", {
                                attrs: { label: "药物组", value: "2" },
                              }),
                              _c("el-option", {
                                attrs: { label: "制剂", value: "4" },
                              }),
                              _c("el-option", {
                                attrs: { label: "制剂规格", value: "5" },
                              }),
                              _c("el-option", {
                                attrs: { label: "药品", value: "6" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.addRelevanceForm.drugFlag == 3
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "150px" },
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择药物分类",
                                  },
                                  on: { change: _vm.drugClassifySelectChange },
                                  model: {
                                    value: _vm.addRelevanceForm.drugClassify,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.addRelevanceForm,
                                        "drugClassify",
                                        $$v
                                      )
                                    },
                                    expression: "addRelevanceForm.drugClassify",
                                  },
                                },
                                _vm._l(_vm.drugClassifyDict, function (item) {
                                  return _c("el-option", {
                                    key: item.conceptId,
                                    attrs: {
                                      label: item.conceptVal,
                                      value: item.conceptId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.searchDataList(1)
                                },
                              },
                            },
                            [_vm._v("查询")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { staticStyle: { color: "#409eff" }, attrs: { span: 6 } },
                [
                  _c(
                    "el-link",
                    {
                      staticStyle: {
                        "font-weight": "bolder",
                        "margin-right": "20px",
                        "line-height": "24px",
                      },
                      attrs: { type: "primary1" },
                      on: {
                        click: function ($event) {
                          _vm.addRelevanceForm.searchValue =
                            _vm.nowClickNodeData.name
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.nowClickNodeData.name))]
                  ),
                  _c("span", { staticStyle: { "font-weight": "300" } }, [
                    _vm._v(_vm._s(_vm.nowClickNodeData.comment)),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "relevanceTable",
              staticClass: "relevance-table",
              staticStyle: { width: "100%", overflow: "auto" },
              attrs: {
                border: "",
                data: _vm.addRelevanceTableData,
                height: "400",
                "header-cell-style": { "text-align": "center" },
                "cell-style": { "text-align": "center" },
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  selectable: _vm.rowCanChecked,
                  width: "55",
                },
              }),
              _c("el-table-column", { attrs: { type: "index", width: "50" } }),
              _vm._l(
                _vm.tableOptions[_vm.addRelevanceForm.drugFlag],
                function (data) {
                  return _c("el-table-column", {
                    key: data.id,
                    attrs: {
                      prop: data.prop,
                      label: data.name,
                      fixed: false,
                      resizable: false,
                      sortable: false,
                      "min-width": data.width ? data.width : "80",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              data.prop == "substanceDesc"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            placement: "top",
                                            title: "物质描述",
                                            trigger: "click",
                                            width: "600",
                                          },
                                        },
                                        [
                                          _c("div", [
                                            _c("p", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    scope.row.substanceDesc
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "setTwoLine",
                                              staticStyle: {
                                                color: "#2B60F8",
                                                "font-weight": "700",
                                              },
                                              attrs: {
                                                slot: "reference",
                                                title: "点击查看全部物质描述",
                                              },
                                              slot: "reference",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    scope.row.substanceDesc
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : data.prop == "includeDrugList"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            trigger: "click",
                                            placement: "top",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    "text-align": "center",
                                                  },
                                                },
                                                [
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(scope.row.name)
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._l(
                                                scope.row
                                                  .dkbDrugGroupMappingList,
                                                function (item1) {
                                                  return _c(
                                                    "p",
                                                    { key: item1.id },
                                                    [
                                                      _vm._v(" ["),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(item1.drugId)
                                                        ),
                                                      ]),
                                                      _vm._v("]-"),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(item1.drugName)
                                                        ),
                                                      ]),
                                                      _vm._v("； "),
                                                    ]
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "name-wrapper setTwoLine",
                                              attrs: { slot: "reference" },
                                              slot: "reference",
                                            },
                                            _vm._l(
                                              scope.row.dkbDrugGroupMappingList,
                                              function (item1) {
                                                return _c(
                                                  "span",
                                                  { key: item1.id },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        class: {
                                                          delClass:
                                                            item1.status ===
                                                            "1",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" ["),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(item1.drugId)
                                                          ),
                                                        ]),
                                                        _vm._v("]-"),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              item1.drugName
                                                            )
                                                          ),
                                                        ]),
                                                        _vm._v("； "),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : data.prop == "drugName"
                                ? _c(
                                    "div",
                                    {
                                      attrs: {
                                        title: "点击查看属于该药物的制剂",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: { type: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.clickDrugRowSearch(
                                                scope.row,
                                                1
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(scope.row.drugName)
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : data.prop == "preparationAlias"
                                ? _c(
                                    "div",
                                    {
                                      attrs: {
                                        title: "点击查看该制剂的全部规格",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: { type: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.clickDrugRowSearch(
                                                scope.row,
                                                2
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(scope.row.preparationAlias)
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : data.prop == "preparationSpecDesc"
                                ? _c(
                                    "div",
                                    {
                                      attrs: {
                                        title: "点击查看该制剂规格的全部药品",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-link",
                                        {
                                          attrs: { type: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.clickDrugRowSearch(
                                                scope.row,
                                                3
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                scope.row.preparationSpecDesc
                                              )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _c("div", [
                                    _c("span", [
                                      _vm._v(_vm._s(scope.row[data.prop])),
                                    ]),
                                  ]),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  })
                }
              ),
              _c("template", { slot: "empty" }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/no-booking.svg"),
                    alt: "",
                  },
                }),
                _c("p", [_vm._v("暂无数据")]),
              ]),
            ],
            2
          ),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.addRelevanceTableDataTotal > 0,
                expression: "addRelevanceTableDataTotal>0",
              },
            ],
            attrs: {
              total: _vm.addRelevanceTableDataTotal,
              page: _vm.addRelevanceForm.pageNum,
              limit: _vm.addRelevanceForm.pageSize,
              layout: "total, sizes, prev, pager, next",
              "page-sizes": [20, 50, 100],
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.addRelevanceForm, "pageNum", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.addRelevanceForm, "pageSize", $event)
              },
              pagination: function ($event) {
                return _vm.searchDataList()
              },
            },
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-right": "20px" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.cancleAddTable } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.submitAddTable },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            top: "5px",
            title: "规则列表",
            visible: _vm.conditionEditorVisible,
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.conditionEditorVisible = $event
            },
          },
        },
        [
          _vm.conditionEditorVisible
            ? _c("ConditionEditor", {
                attrs: { categoryDirId: _vm.drugCategoryId },
                on: {
                  saveCondition: function ($event) {
                    return _vm.saveCondition($event)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }