var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-button",
        {
          staticClass: "addClass",
          staticStyle: { float: "right", "margin-bottom": "6px" },
          attrs: { icon: "el-icon-circle-plus-outline", size: "small" },
          on: {
            click: function ($event) {
              return _vm.editCondition()
            },
          },
        },
        [_vm._v("新增规则 ")]
      ),
      _c(
        "el-table",
        {
          attrs: {
            border: "",
            stripe: "",
            "header-cell-style": { "text-align": "center" },
            data: _vm.conditionTableData,
          },
        },
        [
          _c("el-table-column", {
            attrs: { type: "index", align: "center", width: "50" },
          }),
          _c("el-table-column", {
            attrs: { align: "left", label: "规则", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(
                    scope.row.conditionList,
                    function (condition, index) {
                      return _c(
                        "span",
                        { staticClass: "condition-div" },
                        [
                          _c("span", { staticStyle: { color: "#4B56F2" } }, [
                            _vm._v(_vm._s(condition.fieldName)),
                          ]),
                          _vm._v(" 等于 "),
                          _vm._l(condition.val, function (t) {
                            return _c(
                              "el-tag",
                              {
                                staticStyle: { "margin-right": "4px" },
                                attrs: { size: "small" },
                              },
                              [_vm._v(_vm._s(t.name) + " ")]
                            )
                          }),
                          index < scope.row.conditionList.length - 1
                            ? _c(
                                "el-tag",
                                {
                                  staticStyle: { margin: "0px 10px" },
                                  attrs: { type: "info" },
                                },
                                [_vm._v("and")]
                              )
                            : _vm._e(),
                        ],
                        2
                      )
                    }
                  )
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.editCondition(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑 ")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: { color: "#FF3333" },
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.delConditionRow(scope.row)
                          },
                        },
                      },
                      [_vm._v("删除 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            top: "5px",
            title: "条件编辑",
            visible: _vm.conditionEditorDialogVisible,
            width: "70%",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.conditionEditorDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "conditionEditForm",
              staticClass: "el-form",
              attrs: {
                "status-icon": "",
                model: _vm.conditionForm,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.conditionForm.id,
                      expression: "conditionForm.id",
                    },
                  ],
                  attrs: { label: "条件ID:" },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px", float: "left" },
                    attrs: { disabled: true },
                    model: {
                      value: _vm.conditionForm.id,
                      callback: function ($$v) {
                        _vm.$set(_vm.conditionForm, "id", $$v)
                      },
                      expression: "conditionForm.id",
                    },
                  }),
                ],
                1
              ),
              _vm._l(_vm.conditionForm.conditionList, function (item, index) {
                return _c(
                  "el-form-item",
                  {
                    key: "condition-name-" + index,
                    attrs: {
                      label: index == 0 ? "条件:" : "",
                      prop: "conditionList." + index + ".field",
                    },
                  },
                  [
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { float: "left", "margin-bottom": "0px" },
                        attrs: {
                          rules: [{ validator: _vm.validateConditionField }],
                          prop: "conditionList." + index + ".field",
                        },
                      },
                      [
                        _c(
                          "el-select",
                          {
                            staticStyle: {
                              width: "150px",
                              float: "left",
                              "margin-right": "4px",
                            },
                            attrs: { disabled: item.val.length > 0 },
                            model: {
                              value: item.field,
                              callback: function ($$v) {
                                _vm.$set(item, "field", $$v)
                              },
                              expression: "item.field",
                            },
                          },
                          _vm._l(_vm.conditionFieldDict, function (item) {
                            return _c("el-option", {
                              key: item.label + "-" + item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "120px", float: "left" },
                        model: {
                          value: item.func,
                          callback: function ($$v) {
                            _vm.$set(item, "func", $$v)
                          },
                          expression: "item.func",
                        },
                      },
                      [
                        _c("el-option", {
                          attrs: { label: "等于", value: "equals" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticStyle: { float: "left", "margin-bottom": "0px" },
                        attrs: {
                          rules: [
                            {
                              required: true,
                              message: "请选择条件值",
                              trigger: "blur",
                            },
                          ],
                          prop: "conditionList." + index + ".val",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "condition-val" },
                          [
                            _vm._l(item.val, function (t, i) {
                              return _c(
                                "el-tag",
                                {
                                  staticStyle: { "margin-right": "4px" },
                                  attrs: { size: "medium", closable: "" },
                                  on: {
                                    close: function ($event) {
                                      return _vm.removeConditionVal(index, i)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(t.name) + " ")]
                              )
                            }),
                            _c("el-button", {
                              attrs: {
                                size: "mini",
                                round: "",
                                icon: "el-icon-circle-plus-outline",
                                type: "primary",
                                plain: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.addConditionVal(index)
                                },
                              },
                            }),
                          ],
                          2
                        ),
                      ]
                    ),
                    _c("i", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.conditionForm.conditionList.length > 1,
                          expression: "conditionForm.conditionList.length>1",
                        },
                      ],
                      staticClass: "el-icon-remove",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.delCondition(index)
                        },
                      },
                    }),
                  ],
                  1
                )
              }),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "addData",
                      staticStyle: { width: "300px" },
                      attrs: { type: "primary", plain: "" },
                      on: { click: _vm.addCondition },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-circle-plus el-icon--left",
                        staticStyle: {
                          color: "#7C9DF7",
                          width: "16px",
                          height: "16px",
                          display: "inline-block",
                        },
                      }),
                      _vm._v(" 增加条件 "),
                    ]
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              staticStyle: { "padding-right": "30px" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.conditionEditorDialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmSaveCondition },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            top: "5px",
            title: "新增条件值",
            visible: _vm.addConditionValDialogVisible,
            width: "80%",
            "append-to-body": "",
            "close-on-click-modal": false,
            id: "addConditionValDialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addConditionValDialogVisible = $event
            },
          },
        },
        [
          _vm.nowEditConditionField != "form"
            ? _c(
                "div",
                [
                  _c(
                    "el-row",
                    { staticStyle: { "padding-bottom": "10px" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "300px", "margin-right": "20px" },
                        attrs: { placeholder: "", clearable: "" },
                        nativeOn: {
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            ) {
                              return null
                            }
                            return _vm.searchConditionValList(1)
                          },
                        },
                        model: {
                          value: _vm.searchConditionValForm.searchValue,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.searchConditionValForm,
                              "searchValue",
                              $$v
                            )
                          },
                          expression: "searchConditionValForm.searchValue",
                        },
                      }),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.searchConditionValList(1)
                            },
                          },
                        },
                        [_vm._v("查询")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      ref: "conditionValTable",
                      staticStyle: { width: "100%", overflow: "auto" },
                      attrs: {
                        border: "",
                        data: _vm.conditionValTableData,
                        height: "400",
                        "header-cell-style": { "text-align": "center" },
                        "cell-style": { "text-align": "center" },
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "index", width: "50" },
                      }),
                      _vm.nowEditConditionField == "drug"
                        ? _c("el-table-column", {
                            key: "drug1",
                            attrs: {
                              width: "100",
                              property: "id",
                              label: "药物ID",
                            },
                          })
                        : _vm._e(),
                      _vm.nowEditConditionField == "drug"
                        ? _c("el-table-column", {
                            key: "drug2",
                            attrs: {
                              "min-width": "150",
                              property: "drugName",
                              label: "药物名称",
                            },
                          })
                        : _vm._e(),
                      _vm.nowEditConditionField == "drug"
                        ? _c("el-table-column", {
                            key: "drug3",
                            attrs: {
                              width: "80",
                              property: "classifyDesc",
                              label: "中西药标志",
                            },
                          })
                        : _vm._e(),
                      _vm.nowEditConditionField == "drug"
                        ? _c("el-table-column", {
                            key: "drug4",
                            attrs: {
                              "min-width": "180",
                              property: "ingredient",
                              label: "药物成分",
                            },
                          })
                        : _vm._e(),
                      _vm.nowEditConditionField == "drugGroup"
                        ? _c("el-table-column", {
                            key: "drugGroup1",
                            attrs: {
                              width: "100",
                              property: "id",
                              label: "药物组ID",
                            },
                          })
                        : _vm._e(),
                      _vm.nowEditConditionField == "drugGroup"
                        ? _c("el-table-column", {
                            key: "drugGroup2",
                            attrs: {
                              "min-width": "150",
                              property: "name",
                              label: "药物组名称",
                            },
                          })
                        : _vm._e(),
                      _vm.nowEditConditionField == "drugGroup"
                        ? _c("el-table-column", {
                            key: "drugGroup3",
                            attrs: {
                              "min-width": "180",
                              property: "includeDrugList",
                              label: "包含药物列表",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-popover",
                                        {
                                          attrs: {
                                            trigger: "click",
                                            placement: "top",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticStyle: {
                                                    "text-align": "center",
                                                  },
                                                },
                                                [
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(scope.row.name)
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _vm._l(
                                                scope.row
                                                  .dkbDrugGroupMappingList,
                                                function (item1) {
                                                  return _c(
                                                    "p",
                                                    { key: item1.id },
                                                    [
                                                      _vm._v(" ["),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(item1.drugId)
                                                        ),
                                                      ]),
                                                      _vm._v("]-"),
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(item1.drugName)
                                                        ),
                                                      ]),
                                                      _vm._v("； "),
                                                    ]
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "name-wrapper setTwoLine",
                                              attrs: { slot: "reference" },
                                              slot: "reference",
                                            },
                                            _vm._l(
                                              scope.row.dkbDrugGroupMappingList,
                                              function (item1) {
                                                return _c(
                                                  "span",
                                                  { key: item1.id },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        class: {
                                                          delClass:
                                                            item1.status ===
                                                            "1",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(" ["),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(item1.drugId)
                                                          ),
                                                        ]),
                                                        _vm._v("]-"),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              item1.drugName
                                                            )
                                                          ),
                                                        ]),
                                                        _vm._v("； "),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2588778021
                            ),
                          })
                        : _vm._e(),
                      _vm.nowEditConditionField == "preparation"
                        ? _c("el-table-column", {
                            key: "preparation1",
                            attrs: {
                              width: "100",
                              property: "id",
                              label: "制剂ID",
                            },
                          })
                        : _vm._e(),
                      _vm.nowEditConditionField == "preparation"
                        ? _c("el-table-column", {
                            key: "preparation2",
                            attrs: {
                              "min-width": "150",
                              property: "preparationAlias",
                              label: "制剂名称",
                            },
                          })
                        : _vm._e(),
                      _vm.nowEditConditionField == "preparation"
                        ? _c("el-table-column", {
                            key: "preparation3",
                            attrs: {
                              width: "80",
                              property: "typeName",
                              label: "制剂剂型",
                            },
                          })
                        : _vm._e(),
                      _vm.nowEditConditionField == "preparation"
                        ? _c("el-table-column", {
                            key: "preparation4",
                            attrs: {
                              width: "80",
                              property: "routeName",
                              label: "用药途径",
                            },
                          })
                        : _vm._e(),
                      _vm.nowEditConditionField == "preparation"
                        ? _c("el-table-column", {
                            key: "preparation5",
                            attrs: {
                              width: "80",
                              property: "siteName",
                              label: "给药部位",
                            },
                          })
                        : _vm._e(),
                      _vm.nowEditConditionField == "preparation"
                        ? _c("el-table-column", {
                            key: "preparation6",
                            attrs: {
                              width: "150",
                              property: "drugTypeSpecName",
                              label: "中药类型及规格",
                            },
                          })
                        : _vm._e(),
                      _vm.nowEditConditionField == "preparation"
                        ? _c("el-table-column", {
                            key: "preparation7",
                            attrs: {
                              width: "150",
                              property: "drugProcMethodName",
                              label: "中药炮制方法",
                            },
                          })
                        : _vm._e(),
                      _vm.nowEditConditionField == "route"
                        ? _c("el-table-column", {
                            key: "route1",
                            attrs: {
                              width: "100",
                              property: "id",
                              label: "给药途径ID",
                            },
                          })
                        : _vm._e(),
                      _vm.nowEditConditionField == "route"
                        ? _c("el-table-column", {
                            key: "route2",
                            attrs: {
                              "min-width": "100",
                              property: "conceptVal",
                              label: "给药途径名称",
                            },
                          })
                        : _vm._e(),
                      _vm.nowEditConditionField == "site"
                        ? _c("el-table-column", {
                            key: "site1",
                            attrs: {
                              width: "100",
                              property: "id",
                              label: "给药部位ID",
                            },
                          })
                        : _vm._e(),
                      _vm.nowEditConditionField == "site"
                        ? _c("el-table-column", {
                            key: "site2",
                            attrs: {
                              "min-width": "100",
                              property: "conceptVal",
                              label: "给药部位名称",
                            },
                          })
                        : _vm._e(),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          align: "center",
                          width: "100",
                          fixed: "right",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !scope.row.selected,
                                          expression: "!scope.row.selected",
                                        },
                                      ],
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addConditionValFromTable(
                                            scope.row
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("选择 ")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4176663589
                        ),
                      }),
                    ],
                    1
                  ),
                  _c("pagination", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.conditionValTableDataTotal > 0,
                        expression: "conditionValTableDataTotal>0",
                      },
                    ],
                    attrs: {
                      total: _vm.conditionValTableDataTotal,
                      page: _vm.searchConditionValForm.pageNum,
                      limit: _vm.searchConditionValForm.pageSize,
                      layout: "total, sizes, prev, pager, next",
                      "page-sizes": [20, 50, 100],
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(
                          _vm.searchConditionValForm,
                          "pageNum",
                          $event
                        )
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(
                          _vm.searchConditionValForm,
                          "pageSize",
                          $event
                        )
                      },
                      pagination: function ($event) {
                        return _vm.searchConditionValList()
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.nowEditConditionField == "form"
            ? _c(
                "div",
                { staticStyle: { height: "300px", overflow: "auto" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "输入关键字进行过滤" },
                    model: {
                      value: _vm.searchConditionValForm.treeFilterText,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.searchConditionValForm,
                          "treeFilterText",
                          $$v
                        )
                      },
                      expression: "searchConditionValForm.treeFilterText",
                    },
                  }),
                  _c("el-tree", {
                    ref: "conceptFormTree",
                    attrs: {
                      data: _vm.conceptFormTreeData,
                      "show-checkbox": "",
                      "node-key": "conceptId",
                      "check-strictly": true,
                      "default-checked-keys": _vm.formTreeDefaultCheckedKeys,
                      "filter-node-method": _vm.filterFormTreeNode,
                      props: _vm.formTreeProps,
                    },
                    on: { "check-change": _vm.handleFormTreeCheckChange },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }